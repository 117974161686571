<template>
  <div style="width:100%;height:100%;overflow-y:auto">
    <div style="margin: 15px 0;">
      <h4>{{blog.title}}</h4>
      <div v-if="blog.description">
        <div class="articleBox">
          <article class="introText" v-html="blog.description"></article>
        </div>
      </div>
    </div>
    <LightGallery :items="blog.items" @Closed="$emit('Closed')"></LightGallery>
  </div>
</template>
<script>
  import AutoPano from '../../BlogSkins/AutoPano'
  import BlogItemMap from '../../BlogSkins/BlogItemMap'
  import LightGallery from '../../LightGallery'
  export default {
    components: {
      AutoPano,
      BlogItemMap,
      LightGallery,
    },
    props: {
    },
    data() {
      return {
        bgaudio: false
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      blog() {
        return {
          user: this.publicData.tour.user || {},
          items: this.publicData.groupItems || [],
          ...this.getGroup(this.publicData.groupGuid)
        }
      },
      groups() {
        return this.publicData.tour.groups
      }
   },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      getGroup(groupGuid) {
        console.log(groupGuid, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].guid == groupGuid) {
            return this.groups[i]
          }
        }
      },
      toPano() {
        this.$emit('toPano')
      },
      toUserUrl() {
        this.$emit('toUserUrl')
      },
      praise() {
        this.$emit('praise')
      },
      share() {
        this.$emit('share')
      },
      clickPano(item) {
        if (this.toPano) {
          this.toPano(item.linkItem.guid)
        }
      },
      closeOtherAudios(e) {
        const thisav = e.srcElement
        const av = [...document.getElementsByTagName('audio'), ...document.getElementsByTagName('video')]
        console.log(av)
        for (const i in av) {
          if (thisav != av[i]) { av[i].pause() }
        }
      },
      getBgImgStyle(url, obj) {
        return {
          ...obj,
          'background-image': `url(${url})`,
          'background-size': 'cover',
          'background-position': 'center',
        }
      },

    }
  }
</script>
<style lang="css">
  .lg-backdrop {
    z-index: 1050;
  }
</style>
<style lang="css" scoped>

</style>

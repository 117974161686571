import { render, staticRenderFns } from "./TourGalleryView.vue?vue&type=template&id=5fe575bf&scoped=true&"
import script from "./TourGalleryView.vue?vue&type=script&lang=js&"
export * from "./TourGalleryView.vue?vue&type=script&lang=js&"
import style0 from "./TourGalleryView.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../app/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fe575bf",
  null
  
)

export default component.exports